import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

// Importando Trix e seu CSS
import "trix";
import "@rails/actiontext";
import "trix/dist/trix.css"; // Importar o CSS do Trix aqui

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener("turbolinks:load", () => {
  import('select2').then((select2) => {
    $(document).ready(function() {
      $('.select-class').select2();
    });
  });

  import('dropzone').then(Dropzone => {
    Dropzone.autoDiscover = false;
  });

  document.querySelectorAll(".alert").forEach((alert) => {
    setTimeout(() => {
      alert.classList.remove("show")
    }, 2900);
  });
});

import("./multiselect");
import("./order-show");

require("trix")
require("@rails/actiontext")